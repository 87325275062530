<template>
  <div id="login" style="height: 100%; background-color: rgba(255, 255, 255, 0); margin: 0 auto;padding: 0" >
    <div class="main-container" style=" background-color: rgba(255, 255, 255, 0)">
      <md-dialog :md-active.sync="showLoginForceForm"
                 :before-close="() => showLoginForceForm = false"
      >
        <md-dialog-content style="padding: 10px">
          <div>Пользователь с вашей ролью уже залогинен в систему. вы уверены что хотите заменить его на дежурстве?</div>
          <md-button @click="login(true)" class="md-raised md-primary">Да</md-button>
          <md-button @click="showLoginForceForm = false" class="md-raised md-primary">Нет</md-button>
        </md-dialog-content>
      </md-dialog>

      <div class="main" style="opacity: 1 !important; margin: 20px 20px; display: flex; flex-direction: column; justify-content: center; align-items: center; padding-bottom: 20px; max-width: 90vw">
        <h3>Автоматизированная Информационная Система Предупреждения о Цунами </h3>
        <H3>(АИСПЦ)</H3>
        <form action="">
          <md-field style="width: 400px; max-width: 70vw">
            <label>Логин</label>
            <md-input v-model.trim="username" placeholder="Логин" ></md-input>
          </md-field>
          <md-field>
            <label>Пароль</label>
            <md-input v-model.trim="password" type="password" placeholder="Пароль" ></md-input>
          </md-field>

          <br />
          <md-button :disabled="!username || !password" @click="login(false)" class="md-raised md-primary">Войти</md-button>
          <md-button class="md-raised md-primary" @click="$router.push('/signup')">Регистрация</md-button>

          <br />

        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import {signin} from '../api/auth-controller'
  import {getItem} from "../utils/persistanceStorage";
  import eventBus from '../components/eventBus.js'

  import {mapActions} from 'vuex'

  export default {
    name: "Login",
    //Custom style for main and input for make the page responsive:
    props: {
      mainStyle: String,
    },
    data() {
      return {
        username: "",
        password: "",
        passwordVisible: false,
        showLoginForceForm: false
      }
    },
    methods: {
      ...mapActions(['showInfoAlert', 'startLoadingSignals', 'stopLoadingSignals']),
      async login(force) {
        // console.log('login', this.username, this.password, force)
        signin(this.username, this.password, force)
          .then(async () => {
            console.log('login-1')

            // return getRequests()
            // console.log('auth', getItem('auth'))
            if (getItem('auth')) {
              console.log('login-2')
              eventBus.$emit('login-event')
              console.log('login-3')
              console.log('query', this.$route.query)

              if (this.$route.query && this.$route.query.redirect) {
                console.log('login-4')
                await this.$router.push(this.$route.query.redirect)
                  .catch(e => console.log(e))
                return;
              }
              console.log('login-5')

              if (getItem('auth').roles.includes("master") || getItem('auth').roles.includes("administrator")) {
                this.$router.push('/users')
              } else if (getItem('auth').roles.includes("oceanographer")) {
                this.startLoadingSignals()
                this.$router.push('/oceanographer')
              } else if (getItem('auth').roles.includes("seismologist") ) {
                this.startLoadingSignals()
                this.$router.push('/seismologist')
              } else if (getItem('auth').roles.includes("expert"))  {
                this.$router.push('/expert')
              }
            }
          }).then(result => {
          return result
          // console.log('result', JSON.stringify(result))
        })
          .catch(e => {
            this.stopLoadingSignals()
            // console.log('catch login', e)
            if (e.status === 409) {
              this.forceLoginAsk()
            } else if (e.data && e.data.message) {
              this.showInfoAlert(e.data.message)
            }
          })
        // this.$router.push('/signin-done')
      },
      async forceLoginAsk() {
        this.showLoginForceForm = true
        // if (await confirm('Пользователь с вашей ролью уже залогинен в систему. вы уверены что хотите заменить его на дежурстве?')) {
        //   this.login(true)
        // }
      },
      register() {
        this.$router.push({path: '/signup'});
      },
    }
  };
</script>

<style>
  @media screen and (min-width: 800px) {
    .main-container {
      /*background: no-repeat center/100% url("../assets/volna_okean_vsplesk_54955_1280x1280.jpg");*/
      padding: 0;
    }
  }


  #login {
    background-color: aliceblue;
    background-size: cover;
    width: 100%;
    height: 100%;

  }

  .main-container {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightsteelblue;
    padding: 0 20px;
    position: relative;
  }

  .main {
    background: rgba(255, 255, 255, 0.6);
    text-align: center;
    border-radius: 3rem;
    box-shadow: 0 0 8px -5px #000000;
    width: 750px;
    margin: auto 20px;
    padding: 10px;
    /*padding: 60px;*/
  }


  h1 {
    cursor: default;
    user-select: none;
  }

  .input {
    width: 400px;
    max-width: 90vw;
    font-weight: 400;
    font-size: 20px;
    padding: 10px;
  }
  /*input.login {*/
    /*border-radius: 3rem;*/
    /*border: none;*/
    /*text-align: center;*/
    /*outline: none;*/
    /*margin: 10px;*/
    /*box-sizing: border-box;*/
  /*}*/

  input:hover {
    box-shadow: 0px 0px 8px -5px #000000;
  }

  input:active {
    box-shadow: 0px 0px 8px -5px #000000;
  }


  img {
    height: 2.2rem;
    margin: 10px;
    user-select: none;
  }

  img:hover {
    box-shadow: 0px 0px 8px -5px #000000;
    cursor: pointer;
    border-radius: 200rem;
  }
</style>
